.form-user-guide {

  p {
    padding-bottom: 0.8rem;
  }

  .action-icon {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .navigation-widget {
    strong {
      display: block;
    }
  }

}