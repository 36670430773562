@import "../../../../colours";

.content-config-landing-screen {

  .option-card {
    cursor: pointer;

    &:hover {
      background-color: $grey-xlight;
    }

    .card-title {
      h4 {
        font-weight: 800;
      }
    }

    .card-subtitle {
      display: block;
      min-height: 3rem;
      margin-top: 5px;
    }

    .card-action {
      margin-top: 5px;
    }

  }

}