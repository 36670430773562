@import "../../../colours";

.document-version-modal {

  .history-list {
    width: 100%;
    display: block;

    .list-item {
      display: block;
      padding: 5px;
      border-bottom: 1px solid $grey-light;

      .item-date {
        display: block;
        font-size: small;
        font-weight: 800;
      }

      .item-title {
        font-size: medium;
        margin-top: 5px;
        line-break: anywhere;
      }
    }
  }

}