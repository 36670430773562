.subscription-finish-component {
  margin-top: 60px;

  .success-icon {
    width: 200px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    animation-name: success-icon-did-appear;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
  }

  @keyframes success-icon-did-appear {
    0% { transform: scale(0) }
    90% { transform: scale(1.2) }
    100% { transform: scale(1) }
  }

  .success-finish-title {
    font-size: xx-large;
    font-weight: 800;
    display: block;
  }

  .success-finish-message {
    font-size: x-large;
  }

}