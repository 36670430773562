@import '../../../colours';

$appear-animation-duration : 600ms;

@mixin login-animation-config {
  animation-duration: $appear-animation-duration;
  //animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.login-screen {
  padding: 0;

  .fancy-panel {
    background-color: $brand-primary;
    min-height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    animation-name: fancy-panel-appear;
    @include login-animation-config;

    .fancy-image {
      width: 60%;
      min-width: 120px;
      height: 100vh;
      max-height: 400px;
      margin: auto;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: none;
      animation-name: fancy-hero-appear;
      @include login-animation-config;
    }

    @media (min-width: 992px) {
      .fancy-image {
        display: block !important;
      }
    }

    .vertical-shadow {
      width: 10px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.2));
    }
  }

  .form-area {
    display: flex;
    align-items: center;
    animation-name: login-form-appear;
    @include login-animation-config;

    form {
      width: 100%;
      display: block;
      text-align: center;

      .logo {
        width: 100%;
        height: 60px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        margin: 20px 0;
      }

      .form-row {
        margin-top: 20px;
      }

      label {
        font-weight: 800;
        display: block;
        margin-bottom: 5px;
      }

      input[type=text], input[type=password] {
        text-align: center;
      }
    }

  }

  @keyframes fancy-panel-appear {
    0% { transform: translateX(-100%) }
    50% { transform: translateX(0) }
    100% { transform: translateX(0) }
  }

  @keyframes fancy-hero-appear {
    0% { opacity : 0; transform: translateX(-20%) }
    50% { opacity : 0; transform: translateX(-20%) }
    100% { opacity : 1; transform: translateX(0) }
  }

  @keyframes login-form-appear {
    0% { opacity : 0; transform: translateY(-10%) }
    50% { opacity : 0; transform: translateY(-10%) }
    100% { opacity : 1; transform: translateY(0) }
  }

}