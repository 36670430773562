@import '../../../../../../colours';

.form-input-photo-selector-component {

  label {
    font-weight: bold;
    display: block;
  }

  .image-container {
    width: 100%;
    height: 130px;
    display: block;
    overflow: hidden;

    .image-dummy {
      width: 120px;
      height: 120px;
      border-radius: 5px;
      border: 1px solid $grey-light;
      background-color: $grey-xlight;
      display: inline-block;
      margin-right: 5px;
      margin-top: 5px;
    }
  }

}