@import "../../../../../../colours";

.form-input-date-time-component {
  padding: 5px;

  .label {
    display: block;
    font-size: medium;
  }

  .date-content {
    display: flex;
    align-items: center;
    margin-top: 5px;

    .in-label {
      display: block;
      font-size: small;

      &.prefix {
        margin-right: 5px;
      }

      &.suffix {
        margin-left: 5px;
      }
    }

    .date-contain {
      flex: 1 1;
      display: flex;
      border: 1px solid $grey-primary;
      align-items: center;
      padding: 5px;

      .icon {
        width: 25px;
        height: 25px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      .text {
        flex: 1 1;
        margin-left: 5px;
      }
    }
  }

}