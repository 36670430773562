@import "../../../colours";

.weather-widget-component {
  border-radius: 10px;
  background-color: $blue-primary-alt;
  color: $text-colour-inverse;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;

  .weather-name {
    padding: 0 10px;
    text-align: center;
  }

  .weather-icon-container {
    display: flex;
    text-align: center;
    align-items: center;

    .weather-icon {
      width: 60px;
      height: 60px;
      display: inline-block;
      margin: auto;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .temperature-headline {
    font-size: xx-large;
    font-weight: 800;
    text-align: center;
  }

  .temperature-info {
    font-size: small;
  }



}