@import "../../../colours";

.subscription-company-details-component {

  .summary-box {
    border: 2px solid $brand-secondary-alt;
    border-radius: 10px;
    padding: 10px;

    .summary-title {
      text-align: center;
      font-size: x-large;
      font-weight: bold;
    }

    .summary-subtitle {
      text-align: center;
      font-size: xx-large;

      .emphasis {
        font-weight: bold;
      }
    }

    .summary-fineprint {
      font-size: small;
      text-align: center;
    }

    .summary-text {
      text-align: center;
      font-size: large;
    }
  }

}