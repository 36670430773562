@import "../../../colours";

.document-widget {
  border: 1px solid $brand-secondary-alt;
  border-radius: 10px;
  padding: 10px;
  text-align: center;

  .widget-title {
    min-height: 2.6em;
    font-weight: bold;
    font-size: medium;
    display: block;
    margin: 5px 0;
  }

  .widget-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .widget-subtitle {
    display: block;
    font-style: italic;
    font-size: small;
    margin: 5px 0;
  }

}