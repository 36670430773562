@import "../../../colours";

.welcome-user-screen {
  .header-block {
    background-color: $brand-secondary;
    color: $white;
    padding-top: 40px;
    padding-bottom: 40px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }

  h1 {
    font-size: xx-large;
    font-weight: bold;
    color: $black;
  }

  h2 {
    font-size: xx-large;
    font-weight: bold;
  }

  h4 {
    font-size: large;
    font-weight: bold;
    color: $orange-primary;
  }

  .heading-logo {
    width: 80%;
  }

  .app-store-badge {
    width: auto;
    height: 60px;
  }

  .preview-image {
    background-size: contain;
  }

}