.organiser-notes-component {

  .card {
    .card-header {
      .row {
        width: 100%;
      }
    }
  }

}