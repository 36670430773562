@import "../../colours";

.video-player-container {
  position: relative;

  .video-player {
    background: #000;
    position: absolute;
    inset: 0;

    video {
      width: 100%;
      height: 100%;
    }
  }

  .video-overlay {
    inset: 0;
    display: flex;
    flex-direction: column;
    position: absolute;

    .aligner {
      flex: 1 1;
    }

    .video-controls {
      width: 100%;
      display: flex;
      padding: 40px 10px 10px 10px;
      flex-direction: row;
      align-items: center;
      background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
      animation-name: video-controls-did-appear;
      animation-duration: 200ms;
      animation-fill-mode: forwards;

      &.hide-controls {
        animation-direction: reverse;
        animation-duration: 1200ms;
      }

      .action-button {
        width: 40px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }

      .seek-bar {
        flex: 1 1;
        margin: 0 10px;

        .progress {
          cursor: pointer;
        }
      }

      .time {
        font-size: medium;
        font-weight: 800;
        color: $text-colour-inverse;
      }
    }
  }

}

.video-player-modal {
  background: $scrim-dark;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  animation-name: video-modal-did-appear;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  &.dismissing {
    animation-direction: reverse;

    .modal-body {
      animation-direction: reverse;
    }
  }

  .close {
    width: 80px;
    height: 80px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
  }

  .aligner {
    flex: 1 1;
  }

  .modal-body {
    background: $background-primary;
    border-radius: 10px;
    padding: 10px;
    animation-name: video-modal-body-did-appear;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }
}

@keyframes video-modal-did-appear {
  0% { opacity : 0 }
  100% { opacity : 1 }
}

@keyframes video-modal-body-did-appear {
  0% { opacity: 0; transform: translateY(10%) }
  30% { opacity: 0; transform: translateY(10%) }
  100% { opacity: 1; transform: translateY(0) }
}

@keyframes video-controls-did-appear {
  0% { opacity: 0 }
  100% { opacity: 1 }
}