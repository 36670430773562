@import "../../../../../../colours";

.form-input-equipment-selector-component {

  .label {
    font-size: medium;
    padding: 5px;
  }

  .equipment-content {
    display: flex;
    align-items: center;
    padding: 5px;

    .in-label {
      display: block;
      font-size: small;

      &.prefix {
        margin-right: 5px;
      }

      &.suffix {
        margin-left: 5px;
      }
    }

    .equipment-selector-container {
      flex: 1 1;
      border: 1px solid $grey-primary;
      padding: 5px;
      display: flex;
      align-items: center;

      .icon {
        width: 35px;
        height: 35px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
      }

      .text-content {
        flex: 1 1;
        display: block;
        font-size: medium;
        margin-left: 5px;
      }
    }
  }

}