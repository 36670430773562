.subscription-bespoke-form-component {

  .form-title {
    font-size: xx-large;
    font-weight: 800;
  }

  .form-subtitle {
    font-size: x-large;
  }

  .contact-clip {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .contact-clip-icon {
      width: 35px;
      height: 35px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .contact-clip-label {
      font-size: large;
      font-weight: 800;
      flex: 1 1;
      margin-left: 20px;
    }

  }

}