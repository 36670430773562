.induction-detail-screen {

  .image-thumbs {

    .image-thumb {
      width: 120px;
      height: 120px;
      display: inline-block;
      margin-right: 5px;
    }

  }

}