@import "../../../colours";

.calendar-screen {

  .empty-message {
    font-size: large;
    text-align: center;
  }

  .calendar-event-date {
    font-size: large;
    font-weight: 800;
    text-align: center;
  }

  .calendar-event {
    width: 100%;
    display: flex;
    border-radius: 5px;
    border:1px solid $grey-light;
    padding: 10px;
    align-items: center;
    margin-top: 10px;
    cursor: default;

    &:first-child {
      margin-top: 0;
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: $grey-xlight;
      }
    }

    .content-area {
      flex: 1 1;

      .event-title {
        font-weight: bold;
        display: block;
      }

      .event-body {
        font-size: medium;

        ul {
          margin-bottom: 0;
        }
      }
    }

    .chevron {
      width: 20px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

  }

}