.payment-form-component {

  .payment-form-title {
    font-size: x-large;
    font-weight: 800;
    text-align: center;
  }

  .payment-form-price {
    font-size: xx-large;
    font-weight: 800;
    text-align: center;
  }

  .payment-method {
    width: 100%;
    display: flex;
    border-top: 1px solid #efefef;
    cursor: pointer;
    align-items: center;

    .payment-method-details {
      display: block;
      flex: 1 1;
      padding: 10px;
    }
  }

  .card-container {
    padding: 10px 12px;
  }

}