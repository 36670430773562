@import "../../../colours";

.diary-summary-component {

  .summary-widget {
    border: 1px solid $grey-light;
    border-radius: 6px;
    padding: 4px 8px;
    display: flex;
    align-items: center;

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: $grey-xlight;
      }
    }

    .summary-widget-title {
      font-size: large;
      font-weight: bold;
      flex: 1 1;
      padding-right: 8px;
    }

    .summary-widget-indicator {
      width: 1.8rem;
      height: 1.8rem;
      display: block;
    }
  }

}