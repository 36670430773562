.snag-drawing-snag-detail-screen {

  label {
    display: block;
  }

  .snag-title {
    font-size: x-large;
    font-weight: 800;
  }

  .status-area {
    display: flex;
    align-items: center;

    .status-icon {
      width: 30px;
      height: 30px;
      display: block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .text-area {
      display: block;
      flex: 1 1;
      margin-left: 10px;

      .status-title {
        font-size: x-large;
        font-weight: 800;
      }

      .status-subtitle {
        font-size: medium;
      }
    }
  }

}