@import "../../colours";
@import "../../dimensions";

.calendar-component {

  .date-navigation {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .end-button {
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .date-text-container {
      flex: 1 1;
      text-align: center;

      .date-text {
        height: 2.4rem;
        font-size: large;
        font-weight: 800;
        display: flex;
        align-items: center;
        padding: 0 10px;
      }
    }

  }

  .date-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr repeat(6, 2fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    border-collapse: collapse;
    border-top: 1px solid $grey-secondary;
    border-left: 1px solid $grey-secondary;

    .calendar-day {
      background-color: $grey-light;
      font-weight: 800;
      border-right: 1px solid $grey-secondary;
      border-bottom: 1px solid $grey-secondary;
      padding: 5px;
    }

    .calendar-pad, .calendar-date {
      border-right: 1px solid $grey-secondary;
      border-bottom: 1px solid $grey-secondary;
      padding: 5px;
    }

    .calendar-date {
      cursor: pointer;
      transition: background-color 200ms;

      .date-title-container {
        display: block;
        text-align: right;

        .date-title {
          display: inline-block;
          font-weight: bold;
          text-align: right;
          padding: 2px 5px;
        }
      }

      &.today {
        .date-title {
          background-color: $red-primary;
          color: $text-colour-inverse;
          border-radius: 999px;
        }
      }

      &.active {
        background-color: $brand-primary;
        color: $text-colour-inverse;

        &:hover {
          background-color: $brand-primary-alt;
        }

        &.today {
          .date-title {
            background-color: $text-colour-inverse;
            color: $red-primary;
          }
        }
      }

      &.inactive {
        background-color: $grey-light;
        color: $grey-primary;
      }

      &:hover {
        background-color: $grey-xlight;
      }
    }
  }

}