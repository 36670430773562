@import "../../../../../../colours";

.form-input-text-component {
  padding: 5px;

  .label {
    display: block;
  }

  .textbox-content {
    display: flex;
    margin-top: 5px;
    align-items: center;

    .in-label {
      display: block;
      font-size: small;

      &.prefix {
        margin-right: 5px;
      }

      &.suffix {
        margin-left: 5px;
      }
    }

    .textbox {
      flex: 1 1;
      display: block;
      border-radius: 5px;
      border: 1px solid $grey-primary;
      padding: 5px;
      color: $grey-secondary;

      &.small {
        height: 2.8em;
      }

      &.large {
        height: 4.4em;
      }
    }
  }



}