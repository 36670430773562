@import "../../colours";

$icon-size : 1.6rem;

.pdf-presenter {

  .pdf-wrapper {
    overflow: auto;
    text-align: center;
  }

  .pdf-controls {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $grey-light;
    border-radius: 10px;
    padding: 10px;

    .fill-space {
      flex: 1 1;
    }

    .pdf-action {
      width: $icon-size;
      height: $icon-size;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: background-color 100ms;
      border-radius: 5px;

      &:hover {
        background-color: $scrim-light;
      }

      .page-count {
        font-weight: bold;
        font-size: small;
        padding: 5px;
      }
    }

    .pdf-action-placeholder {
      width: $icon-size;
      height: $icon-size;
    }
  }

}