@import "../../../colours";
@import "../../../dimensions";

.calendar-event-modal {

  .event-title {
    font-size: x-large;
    font-weight: 800;
  }

  .mini-heading {
    font-size: small;
    font-weight: bold;
    color: $brand-primary-alt;
    display: block;
    text-transform: uppercase;
  }

  .text-container {
    border-radius: 2.4rem;
    background: $grey-xlight;
    padding: 8px 10px;
    display: block;
  }

  .user-widget {
    display : inline-block;
    background-color: $brand-primary;
    border-radius: $pill-border-radius;
    padding: 5px 10px;
    font-weight: bold;
    cursor: default;
    color: $text-colour-inverse;
    margin-right: 10px;
  }

}