.snags-drawing-snags-screen {

  .drawing-title {
    font-size: x-large;
    font-weight: 800;
  }

  .drawing-info {

  }

}