@import "../../../../../../colours";

.form-input-staff-selector-component {

  .label {
    font-size: medium;
    padding: 5px;
  }

  .staff-selector-content {
    display: flex;
    align-items : center;
    padding: 5px;

    .staff-list {
      flex: 1 1;
      display: block;

      .staff-item {
        display: flex;
        align-items: center;

        .nameplate {
          width: auto;
          border-radius: 999px;
          font-weight: bold;
          color: $white;
          display: block;
          background-color: $blue-primary;
          padding: 5px;

          .nameplate-label {
            min-height: 25px;
            display: inline-block;
            padding-left: 30px;
            background-size: 25px;
            background-position: left center;
            background-repeat: no-repeat;
          }
        }

        .space {
          flex : 1 1;
        }

        .signature-input {
          border: 1px solid $grey-primary;
          width: 80px;
          height: 30px;
        }

        .delete-icon  {
          width: 25px;
          height: 25px;
          background-size: 25px;
          background-position: center;
          background-repeat: no-repeat;
          margin-left: 5px;
        }
      }

      .button-container {
        width: 100%;
        text-align: center;
        margin-top: 5px;
        display: block;

        .ecare-button {
          width: auto;
        }
      }
    }
  }

}