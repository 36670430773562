@import "../../../../../../colours";

$rating-bar-height : 30px;
$rating-bar-line-height : 4px;
$rating-bar-handle-height: 20px;

.form-input-rating-bar-component {

  .label {
    font-size: medium;
    padding: 5px;
  }

  .rating-bar-container {
    display: flex;
    align-items: center;
    padding: 0 10px;

    .in-label {
      display: block;
      font-size: small;

      &.prefix {
        margin-right: 5px;
      }

      &.suffix {
        margin-left: 5px;
      }
    }

    .rating-bar {
      flex: 1 1;
      height: $rating-bar-height;
      display: block;
      position: relative;

      .rating-bar-line {
        width: 100%;
        height: 2px;
        display: block;
        background: $grey-primary;
        position: absolute;
        margin-top: ($rating-bar-height / 2) - ($rating-bar-line-height / 2);
      }

      .rating-bar-handle {
        width: $rating-bar-handle-height;
        height: $rating-bar-handle-height;
        margin-top: ($rating-bar-height / 2) - ($rating-bar-handle-height / 2);
        margin-left: 25%;
        border-radius: 50%;
        background: $blue-primary-alt;
        position: absolute;
      }
    }
  }


}