.form-input-radio-component {
  padding: 5px;

  .label {
    display: block;
  }

  .radio-content {
    display: flex;
    align-items: center;
    margin-top: 5px;

    .in-label {
      display: block;
      font-size: small;

      &.prefix {
        margin-right: 5px;
      }

      &.suffix {
        margin-left: 5px;
      }
    }

    .radio-group {
      flex: 1 1;
      padding: 5px;
      display: block;

      label {
        font-weight: normal;
        display: block;
      }
    }
  }



}