@import "../../../../../../colours";

.form-input-component {

  .input-container {
    display: block;
    position: relative;
    overflow: hidden;

    .select-overlay {
      inset: 0;
      position: absolute;
      cursor: pointer;
      border: 2px dashed fade-out($blue-primary-alt, 1);
      border-radius: 5px;
      transition: border-color 100ms;
      display: flex;

      .space {
        flex : 1 1;
      }

      .overlay-actions {
        height: 24px;
        background: $blue-primary-alt;
        border-radius: 5px;
        padding: 2px;
        opacity: 0;
        transition: opacity 100ms;
        display: block;
        margin: 2px;

        .overlay-action {
          width: 20px;
          height: 20px;
          display: inline-block;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          border: 1px solid fade-out($grey-primary, 1);
          cursor: pointer;
          transition: border-color 100ms;

          &:hover {
            border-color: $grey-primary;
          }
        }
      }

      &:hover {
        border-color: $blue-primary-alt;

        .overlay-actions {
          opacity: 1;
        }
      }
    }
  }

}