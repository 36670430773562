.health-safety-video-editor {

  .file-selection-area {
    display: flex;
    flex-direction: row;
    align-items: center;

    .button-area {
      width: auto;
    }

    .info-area {
      flex: 1 1;
      text-align: center;
      padding: 5px;

      .filename {
        font-weight: bold;
        display: block;
      }

      .filesize {
        margin-top: 4px;
        display: block;
      }
    }
  }

}