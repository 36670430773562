@import "../../../colours";

.subscription-settings-screen {

  .subscription-info {
    .value {
      font-size: x-large;
      font-weight: 800;
      display: block;
    }
  }

  .subscription-slot {

    &.unfilled {
      border-style: dashed;

      .card-header {
        background-color: transparent !important;
        border-bottom-style: dashed;
      }

      .card-body {

        .add-target {
          display: block;
          border-radius: 5px;
          cursor: pointer;
          text-align: center;
          padding: 10px;
          font-weight: 800;

          &:hover {
            background-color: $grey-xlight;
          }
        }

        .add-icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 2px solid #000;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
        }
      }
    }

    &.warning {
      .card-header {
        background-color: #fff3cd !important;
      }
    }

    &.danger {
      .card-header {
        background-color: $red-primary-alt !important;
        color: $text-colour-inverse;
      }
    }

    .header-container {
      width: 100%;
      display: flex;
      align-items: center;

      .title-area {
        flex: 1 1;
      }

      .icon-area {
        width: 20px;
        height: 20px;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    }

    .user-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .user-content-main {
        flex: 1 1;
        margin-right: 5px;
      }

      .user-content-icon {
        width: 2.0rem;
        height: 2.0rem;
        background-size: 1.6rem;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: $red-primary-alt;
        }
      }
    }

    .footer-arrows {
      width: 100%;
      display: flex;
      flex-direction: row;

      .arrow-button {
        width: 30px;
        height: 30px;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
          background-color: $grey-light;
        }
      }

      .space {
        display: block;
        flex: 1 1;
      }
    }

  }

}