@import '../../../colours';

.drawing-detail-screen {

  .drawing-image-container {
    text-align: center;

    .drawing-image {
      max-width: 100%;
    }
  }

  .unsupported-message {
    .icon-image {
      width: 80px;
      height: 80px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: auto;
    }

    .message-text {
      font-size: x-large;
      font-weight: 800;
      color: $brand-primary;
      padding: 20px;
      text-align: center;
      margin: auto;
    }
  }

}