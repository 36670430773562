.project-selection-screen {

  .client-header-container {
    width: 100%;
    display: flex;
    align-items: center;

    .client-logo {
      width: 40px;
      height: 40px;
      display: inline-block;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #EFEFEF;
      border-radius: 50%;
    }

    .client-title {
      flex: 1 1;
      margin-left: 10px;
    }

  }



}