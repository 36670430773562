@import "../../../../../../colours";

.form-input-multi-select-component {
  display: flex;
  padding: 5px;
  align-items: center;

  .label {
    flex: 1 1;
  }

  .option {
    width: auto;
    height: auto;
    border: 2px solid $grey-primary;
    padding: 5px 8px;
    font-size: medium;
    font-weight: bold;
    border-radius: 5px;
    margin-left: 5px;
  }

}