@import "../../../colours";

.toolbox-video-list-component {

  .video-item {

    .video-thumbnail {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      background-color: $grey-xlight;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .action-container {
        inset: 0;
        background-color: rgba(0,0,0,0.1);
        background-size: 2.4rem;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        position: absolute;
        cursor: pointer;
        transition: background-color 200ms;

        &:hover {
          background-color: rgba(0,0,0,0.15);
        }

        .action-stack {
          width: 1.8rem;
          height: auto;
          border: 1px solid $grey-light;
          border-radius: 1.8rem;
          background: $background-primary;
          box-shadow: 0 0 5px $shadow-light;
          margin: 5px;
          position: absolute;
          display: block;
          right: 0;
          top: 0;
          overflow: hidden;
          box-sizing: border-box;

          .action-button {
            width: 1.8rem;
            height: 1.8rem;
            background-size: 1rem;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            transition: background-color 200ms;
            border-top: 1px solid $grey-light;

            &:first-child {
              border-top: 0;
            }

            &:hover {
              background-color: $grey-xlight;
            }

            &.danger {
              &:hover {
                background-color: $red-primary;
              }
            }
          }
        }
      }

    }

    .video-info {
      display: block;

      .video-title {
        font-size: large;
        font-weight: 800;
      }

      .video-subtitle {
        height: 1.2rem;
        font-size: medium;
        overflow:hidden;
      }
    }

  }

}