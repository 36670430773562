.under-construction-screen {

  .image {
    width: 100%;
    height: 320px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .title {
    font-size: xx-large;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }

  .message {
    font-size: large;
    text-align: center;
    margin-top: 10px;
  }

}