@import "../../../colours";

.organiser-todo-component {

  .table_check {
    width: 2.4rem;
    height: 2.4rem;
    border: 2px solid $brand-primary-alt;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    transition: background-color 200ms;

    &:hover {
      background-color: $grey-xlight;
    }

    &.active {
      &::before {
        content: "";
        inset: 0;
        background: $brand-primary-alt;
        margin: 4px;
        position: absolute;
        border-radius: 50%;
      }
    }
  }

}