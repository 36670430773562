.subscription-landing-screen {

  .sub-screen-title {
    font-size: xx-large;
    font-weight: bold;
  }

  .sub-screen-subtitle {
    font-size: x-large;
  }

}