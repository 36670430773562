@import "../../../../../colours";

.form-editor-component {
  padding-bottom: 100px;

  .save-follow {
    display: block;
    text-align: right;
    padding: 20px;
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid rgba(0,0,0,0.3);
  }

  .form-section {
    .card-header {
      display: flex;
      align-items: center;

      .form-section-title {
        font-size: x-large;
        font-weight: bold;
        display: block;
        flex : 1 1;

        .badge {
          font-size: small;
        }
      }
    }
  }

  .form-question {
    border: 2px solid $white;
    margin-bottom: 10px;
    position: relative;
    border-radius: 5px;
    transition: border 100ms;

    &:hover {
      border-color: $grey-primary;

      .form-question-actions {
        opacity: 1;
      }
    }

    .form-question-actions {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.8);
      margin: 2px;
      border-radius: 5px;
      border: 1px solid $white;
      opacity: 0;
      transition: opacity 100ms;

      .actions {
        padding-top: 3px;
      }

      &:hover {
        border:1px solid $grey-xlight;
      }
    }

    .form-question-body {
      min-height: 2rem;
      padding: 5px;
    }

    .form-question-footer {
      display: block;

    }
  }

  .add-item-button {
    width: 100%;
    border: 2px dotted $grey-secondary;
    border-radius: 10px;
    cursor: pointer;
    opacity: 0.8;
    padding: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    transition: background-color 100ms, opacity 100ms;

    .space {
      flex : 1 1;
    }

    .icon {
      width: 1.4em;
      height: 1.4em;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 5px;
      display: block;
    }

    .text-label {
      font-size: medium;
      font-style: italic;
      display: inline-block;
    }

    &:hover {
      opacity: 1;
      background: rgba(0,0,0,0.05);
    }
  }

  .actions {
    width: auto;
    padding: 0 5px;
    line-height: 20px;

    .action-item {
      width: 25px;
      height: 25px;
      display: inline-block;
      border-radius: 5px;
      cursor: pointer;
      padding: 5px;
      box-sizing: border-box;
      background-size:20px;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid rgba(0,0,0,0);
      transition: border 100ms, background-color 100ms;
      margin-right: 5px;

      &:hover {
        border-color: rgba(0,0,0,0.2);
        background-color: rgba(0,0,0,0.05);
      }
    }
  }

  .editor_flex {
    width: 100%;
    display: flex;
    align-items: center;

    .fill-space {
      flex: 1 1;
    }

    .clickable {
      cursor: pointer !important;
    }
  }

}