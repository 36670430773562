@import "../../../../../../colours";

.form-input-signature-component {
  padding: 5px;

  .label {
    font-size: medium;
  }

  .signature-container {
    display: flex;
    align-items: center;

    .in-label {
      display: block;
      font-size: small;

      &.prefix {
        margin-right: 5px;
      }

      &.suffix {
        margin-left: 5px;
      }
    }

    .signature-area {
      flex: 1 1;
      height: 8rem;
      border: 1px solid $grey-primary;
      border-radius: 5px;
      margin-top: 5px;
    }
  }



}