@import "../../colours";

.settings-side-panel-content {

  .side-settings-profile-panel {
    border: 2px solid $brand-primary;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .account-circle {
      width: 60px;
      height: 60px;
      display: block;
      border: 2px solid $brand-primary;
      border-radius: 50%;
      cursor: pointer;
    }

    .info-area {
      flex: 1 1;
      margin-left: 10px;

      .profile-name {
        font-size: x-large;
        font-weight: 800;
        color: $brand-primary-alt;
        display: block;
      }

      .profile-subtitle {
        display: block;
        margin-top: 2px;
      }
    }
  }

}
