@import "../../colours";
@import "../../dimensions";

.segment-tab-bar {
  display: flex;
  align-items: center;
  flex-direction: column;

  .segment-container {
    display: block;
    padding: 0 5px 5px 5px;
    border: 1px solid $grey-light;
    border-radius: 2.4rem;

    .segment-tab {
      display: inline-block;
      font-size: medium;
      font-weight: 800;
      padding: 8px 15px;
      border-radius: $pill-border-radius;
      text-align: center;
      cursor: pointer;
      border: 1px solid $grey-xlight;
      transition: border 100ms;
      margin-left: 5px;
      margin-top: 5px;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        border-color: $grey-light;
      }

      &.active {
        background-color: $blue-primary-alt;
        color: $text-colour-inverse;
      }
    }
  }

}